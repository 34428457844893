



































import {
    defineComponent,
    ref,
    // useContext,
    // onMounted
} from '@nuxtjs/composition-api';

// import {
//     WntrProductDetails,
//     WntrProductPrice
// } from '~/modules/GraphQL/types';

// import { useContent } from '~/composables';

export default defineComponent({
    name: 'Badges',
    components: {
        
    },
    setup(props, context) {

    },
    props: {
        data: {
            type: Array,
            default () {
                return {
                    name: String,
                    icon: String,
                    badgeClass: String
                }
            }
        },
        id: String
    }
})
