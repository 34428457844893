















































export default {
    name: 'ProductRow',
    components: {
        
    },
    props: {
        wrapper: {
            type: Boolean,
            default: true
        },
        padding: {
            type: String,
            default: ''
        },
        margin: {
            type: String,
            default: null
        },
        titleBorderColor: {
            type: String,
            default: 'white'
        },
        includeFavourite: {
            type: Boolean,
            default: true
        },
        imageClass: {
            type: String,
            default: null
        },
        openLinks: {
            type: String,
            default: null
        },
        room: {
            type: String,
            default: null
        },
        rowCols: {
            type: Object,
            default() {
                return {
                    sm: {
                        type: Number,
                        default: 2
                    },
                    lg: {
                        type: Number,
                        default: 4
                    }
                }
            }
        },
        background: String,
        title: String,
        description: String,
        productCardTitle: {
            type: String,
            default: null
        },
        productCardDetails: {
            type: Array,
            default() {
                return [
                    {
                        image: {
                            type: Object,
                            default() {
                                return {
                                    src: String,
                                    width: Number,
                                    height: Number
                                }
                            }
                        },
                        title: String,
                        description: String,
                        price: String,
                        badges: {
                            type: Array,
                            default() {
                                return [
                                    {
                                        title: String,
                                        icon: String,
                                        badgeClass: String
                                    }
                                ]
                            }
                        },
                        cta: {
                            type: Object,
                            default() {
                                return {
                                    title: String,
                                    url: Object
                                }
                            }
                        },
                        widths: Array,
                        depths: Array,
                        heights: Array
                    }
                ]
            }
        },
        cardDetails: {
            type: Array,
            default() {
                return [
                    {
                        image: {
                            type: Object,
                            default() {
                                return {
                                    src: String,
                                    width: Number,
                                    height: Number
                                }
                            }
                        },
                        title: String,
                        description: String,
                        price: String,
                        badges: {
                            type: Array,
                            default() {
                                return [
                                    {
                                        title: String,
                                        icon: String,
                                        badgeClass: String
                                    }
                                ]
                            }
                        },
                        cta: {
                            type: Object,
                            default() {
                                return {
                                    title: String,
                                    url: Object
                                }
                            }
                        }
                    }
                ]
            }
        },
        // cta: {
        //     type: Object,
        //     default() {
        //         return {
        //             title: String,
        //             url: {
        //                 type: Object,
        //                 default() {
        //                     return {
        //                         name: 'home'
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    },
    computed: {
        
    },
    data() {
        return {
            
        }
    }
}
