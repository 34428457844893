import { render, staticRenderFns } from "./PDP-Component.vue?vue&type=template&id=1e36a5e1&"
import script from "./PDP-Component.vue?vue&type=script&lang=ts&"
export * from "./PDP-Component.vue?vue&type=script&lang=ts&"
import style0 from "./PDP-Component.vue?vue&type=style&index=0&id=1e36a5e1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/var/www/components/Breadcrumbs.vue').default,Badges: require('/var/www/components/Badges.vue').default,Accordion: require('/var/www/components/Accordion.vue').default,Favourite: require('/var/www/components/Favourite.vue').default,FormInputNumber: require('/var/www/components/FormInputNumber.vue').default,IntersectionalBanner: require('/var/www/components/IntersectionalBanner.vue').default,ProductRow: require('/var/www/components/ProductRow.vue').default,CardSection: require('/var/www/components/CardSection.vue').default,ZoomCards: require('/var/www/components/ZoomCards.vue').default,FullImageCardBanner: require('/var/www/components/FullImageCardBanner.vue').default,FAQs: require('/var/www/components/FAQs.vue').default,TrustPilot: require('/var/www/components/TrustPilot.vue').default,IconBar: require('/var/www/components/IconBar.vue').default})
