

























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'IntersectionalBanner',
    components: {
        
    },
    inject: [
        'image_format'
    ],
    props: {
        data: {
            type: Object,
            default() {
                return {
                    title_1: String,
                    title_2: String,
                    title_3: String,
                    image: {
                        type: Object,
                        default() {
                            return {
                                src: String,
                                width: Number,
                                height: Number,
                                alt: String
                            }
                        }
                    },
                }
            }
        }
    },
    data() {
        return {
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    mounted() {
        
    },
    destroyed() {
        
    },
    methods: {
        
    }
});
