import { render, staticRenderFns } from "./ProductRowDetails.vue?vue&type=template&id=4fa973b3&scoped=true&"
import script from "./ProductRowDetails.vue?vue&type=script&lang=ts&"
export * from "./ProductRowDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ProductRowDetails.vue?vue&type=style&index=0&id=4fa973b3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa973b3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Badges: require('/var/www/components/Badges.vue').default,Favourite: require('/var/www/components/Favourite.vue').default,Accordion: require('/var/www/components/Accordion.vue').default,FormInputNumber: require('/var/www/components/FormInputNumber.vue').default})
