



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
    defineComponent,
    ref,
    // useFetch,
} from '@nuxtjs/composition-api';

import {
    WntrProductDetails,
    WntrProductPrice
} from '~/modules/GraphQL/types';

import VueSlickCarousel from 'vue-slick-carousel'

import Slick from 'vue-slick';

import { useContent, wntrUseCart } from '~/composables';
import { useWntrCartStore } from "~/composables/wntrUseCart/useWntrCartStore";

export default defineComponent({
    name: 'ProductRowDetails',
    components: {
        VueSlickCarousel,
        Slick
    },
    inject: [
        'image_format'
    ],
    setup(props, context) {

        const {
            getProductDetails,
            getProductPrices
        } = useContent();

        const {
            addToCart
        } = wntrUseCart();

        const cartStore = useWntrCartStore();
        const refreshCart = (res) => {

            cartStore.$patch((state) => {
                state.wntrCart = res as WntrCart;
            });
        }

        return {
            getProductDetails,
            getProductPrices,
            addToCart,
            refreshCart
        }

    },
    props: {
        titleBorderColor: {
            type: String,
            default: 'white'
        },
        includeFavourite: {
            type: Boolean,
            default: true
        },
        background: String,
        rowCols: {
            type: Object,
            default() {
                return {
                    sm: {
                        type: Number,
                        default: 2
                    },
                    lg: {
                        type: Number,
                        default: 4
                    }
                }
            }
        },
        title: String,
        description: String,
        cardDetails: {
            type: Array,
            default() {
                return [
                    {
                        image: {
                            type: Object,
                            default() {
                                return {
                                    src: String,
                                    width: Number,
                                    height: Number
                                }
                            }
                        },
                        title: String,
                        description: String,
                        price: String,
                        badges: {
                            type: Array,
                            default() {
                                return [
                                    {
                                        title: String,
                                        icon: String,
                                        badgeClass: String
                                    }
                                ]
                            }
                        },
                        cta: {
                            type: Object,
                            default() {
                                return {
                                    title: String,
                                    url: Object
                                }
                            }
                        },
                        widths: Array,
                        depths: Array,
                        heights: Array
                    }
                ]
            }
        },
        imageClass: {
            type: String,
            default: null
        },
        openLinks: {
            type: String,
            default: null
        },
        room: {
            type: String,
            default: null
        }
        // cta: {
        //     type: Object,
        //     default() {
        //         return {
        //             title: String,
        //             url: {
        //                 type: Object,
        //                 default() {
        //                     return {
        //                         name: 'home'
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    },
    computed: {
        computedWidths() {
            return this.prepareSelect(this.wntrProductProcessed.unit.width.options)
        },
        computedHeights() {
            return this.prepareSelect(this.wntrProductProcessed.unit.height.options)
        },
        computedDepths() {
            return this.prepareSelect(this.wntrProductProcessed.unit.depth.options)
        }
    },
    data() {

        var add_to_basket_label_default = 'Add to basket'
        var add_to_basket_label_busy = 'Adding to basket ...'

        return {
            token: '',
            product_id: null,
            wntrProductProcessed: {},

            product_images: [],

            chosen_finish: 0,
            activeFinish: false,

            productTypes: [
                {
                    label: 'Door',
                    key: 'is_door',
                    requires_hinge_holes: true
                },
                {
                    label: 'Drawer',
                    key: 'is_drawer_front',
                    requires_hinge_holes: false
                }
            ],
            product_type: null,
            chosen_product_type_key: null,
            chosen_product_type: null,

            chosen_true_handleless: null,
            chosen_unit_handing: null,
            chosen_unit_hinge_drilling: null,
            chosen_split_door: null,
            chosen_door_hinges: null,
            chosen_dummy_drawer_clips: null,
            chosen_dummy_drawer_plates: null,
            chosen_blank_height: null,
            chosen_service_void: null,
            
            chosen_gas_lift_kit_key: null,
            chosen_gas_lift_kit: null,
            chosen_tambour_door_kit_key: false,
            chosen_tambour_door_kit: null,
            chosen_sides_and_plinths: null,

            chosen_width: null,
            custom_width: null,
            custom_width_left: null,
            custom_width_right: null,
            final_width: null,
            final_width_left: null,
            final_width_right: null,
            
            chosen_depth: null,
            custom_depth: null,
            final_depth: null,

            chosen_height: null,
            custom_height: null,
            final_height: null,

            show_custom_width_adjustment_message: false,
            show_custom_width_left_adjustment_message: false,
            show_custom_width_right_adjustment_message: false,
            show_custom_height_adjustment_message: false,
            show_custom_depth_adjustment_message: false,

            custom_dimension_height: null,
            custom_dimension_width: null,
            custom_dimension_length: null,
            custom_dimension_depth: null,

            chosen_size: null,

            chosen_cabinet_finish: false,
            chosen_grain_direction_key: false,
            chosen_grain_direction: false,
            chosen_edge: false,
            chosen_glazing: false,
            chosen_handle_position: false,
            // chosen_drawer_box_option_key: false,
            // chosen_drawer_box_option: false,

            chosen_door_type: null,
            chosen_hinge_type_key: null,
            chosen_hinge_type: null,

            holes: [],
            hole_values: [],

            chosen_variant: null,
            custom_variant_height: null,
            final_variant_height: null,
            custom_variant_width: null,
            final_variant_width: null,

            service_void: null,

            chosen_panel_style: null,
            chosen_edge_profile: null,
            chosen_profiled_edges: [],

            notes: null,

            chosen_doors_available: null,
            chosen_storage_options_key: null,
            chosen_storage_options: null,

            quantity: 1,

            final_price: null,
            final_sale_price: null,
            door_requirements: null,
            doors_available: null,
            storage_options: null,
            error: null,
            allow_add_to_basket: false,

            disable_add_to_basket: true,
            add_to_basket_label_default: add_to_basket_label_default,
            add_to_basket_label_busy: add_to_basket_label_busy,
            add_to_basket_label: add_to_basket_label_default,

            settings: {
                dots: true,
                // dotsClass: "slick-dots custom-dot-class",
                // edgeFriction: 0.35,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplaySpeed: 5000,
                // prevArrow: '<i class="fa-solid fa-chevron-left"></i>',
                // nextArrow: '<i class="fa-solid fa-chevron-right"></i>',
                // prevArrow: '',
                // nextArrow: '',
                centerMode: true,
                centerPadding: '0px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            autoplay: false
                        }
                    }
                ]
            },

            sliderForSettings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '.slider-nav',
                cssEase: 'linear'
            },
            sliderNavSettings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                arrows: true,
                dots: false,
                infinite: false,
                centerMode: false,
                focusOnSelect: true,
                prevArrow: '<div class="custom-arrow slick-prev"><span class="fa-stack fa-1x"><i class="fa-solid fa-stack-2x fa-circle"></i><i class="fa-solid fa-stack-2x fa-chevron-left"></i></span></div>',
                nextArrow: '<div class="custom-arrow slick-next"><span class="fa-stack fa-1x"><i class="fa-solid fa-stack-2x fa-circle"></i><i class="fa-solid fa-stack-2x fa-chevron-right"></i></span></div>'
            },

            doors_included: true,

            // chosen_width: null,
            // custom_width: null,
            // final_width: null,
            
            // chosen_depth: null,
            // custom_depth: null,
            // final_depth: null,

            // chosen_height: null,
            // custom_height: null,
            // final_height: null,
            
            hinge_side: null,
            details: null,
            image_prefix: ''
        }
    },
    created() {
        this.image_prefix = this.$config.baseImageUrl
    },
    mounted() {
        if (window.localStorage.wntrUserSet != undefined) {
            this.token = JSON.parse(window.localStorage.wntrUserSet).token;
        }
    },
    methods: {
        // hideModal: function(target) {
        //     console.log('hideModal')
        //     // this.$refs[target].hide()
        //     console.log(this.$refs)
        // },
        prepareSelect(object) {
            const converted = Object.entries(object)
            const numericValues = converted.filter((item) => !isNaN(item[1]))
            const nonNumericValues = converted.filter((item) => isNaN(item[1]))
            return [...nonNumericValues, ...numericValues]
        },
        async retrieveDetails(id) {
            this.resetForm()

            this.wntrProductProcessed = await this.retrieveDetailsExecute(id)
            
            // this.custom_width  = this.details.attributes.unit.width.custom.default
            // this.custom_height = this.details.attributes.unit.height.custom.default
            // this.custom_depth  = this.details.attributes.unit.depth.custom.default

            this.processWntrProduct()
            this.processProductImages()
            this.getUpdatedPrice()

            this.$bvModal.show('modal-selection-' + id);
        },
        resetForm() {

            this.updateQuantity(1)

            this.product_id = null
            this.chosen_true_handleless = null
            this.true_handleless = null
            this.finish = null
            this.product_type = null
            this.unit_handing = null
            this.chosen_unit_hinge_drilling = null
            this.unit_hinge_drilling = null
            this.split_door = null
            this.door_hinges = null
            this.chosen_dummy_drawer_clips = null
            this.dummy_drawer_clips = null
            this.chosen_dummy_drawer_plates = null
            this.dummy_drawer_plates = null
            this.gas_lift_kit = null
            this.tambour_door_kit = null
            this.blank_height = null
            this.chosen_variant = null
            this.custom_variant_height = null
            this.final_variant_height = null
            this.custom_variant_width = null
            this.final_variant_width = null
            this.chosen_service_void = null
            this.service_void = null
            this.chosen_sides_and_plinths = null
            this.sides_and_plinths = null
            // this.drawer_box_option = null
            
            this.chosen_height = null
            this.chosen_width = null
            // this.chosen_width_left = null
            // this.chosen_width_right = null
            this.chosen_depth = null

            this.show_custom_width_adjustment_message = false
            this.show_custom_width_left_adjustment_message = false
            this.show_custom_width_right_adjustment_message = false
            this.show_custom_height_adjustment_message = false
            this.show_custom_depth_adjustment_message = false

            this.custom_height = null
            this.custom_width = null
            this.custom_width_left = null
            this.custom_width_right = null
            this.custom_depth = null

            this.height = null
            this.width = null
            this.width_left = null
            this.width_right = null
            this.depth = null
            
            // this.custom_dimension_height = null
            // this.custom_dimension_width = null
            // this.custom_dimension_length = null
            // this.custom_dimension_depth = null
            this.size = null
            this.hinge_type = null
            this.cabinet_finish = null
            this.grain_direction = null
            this.edge = null
            this.glazing = null
            this.handle_position = null

            this.door_type = null
            this.chosen_hinge_type = null

            // this.holes = []
            // this.hole_values = []
            // this.variant = null
            // this.variant_height = null
            // this.variant_width = null
            // // this.service_void = null
            // this.panel_style = null
            // this.edge_profile = null
            // this.profiled_edges = null
            // this.notes = null

            this.holes = []
            this.hole_values = []
            this.chosen_variant = null
            this.final_variant_height = null
            this.final_variant_width = null
            this.service_void = null
            this.chosen_panel_style = null
            this.chosen_edge_profile = null
            this.chosen_profiled_edges = []
            this.notes = null

            this.doors_available = null
            this.storage_options = null

            this.chosen_doors_available = null
            this.chosen_storage_options = null

            this.quantity = 1
        },
        processWntrProduct() {
            // Duplicate data and if needed move door sizes to each individual finish (to avoid loading identical data)
            if ( this.wntrProductProcessed.door_sizes && this.wntrProductProcessed.finishes ) {
                for ( var i in this.wntrProductProcessed.finishes ) {
                    if ( !this.wntrProductProcessed.finishes[i].door_sizes ) {
                        this.wntrProductProcessed.finishes[i].door_sizes = this.wntrProduct.door_sizes
                    }
                }
            }

            // Set initial default sizes
            if ( this.wntrProductProcessed.unit && this.wntrProductProcessed.unit.width && Object.keys(this.wntrProductProcessed.unit.width.options).length === 1 ) {
                this.chosen_width = Object.keys(this.wntrProductProcessed.unit.width.options)[0]
            }
            if ( this.wntrProductProcessed.unit && this.wntrProductProcessed.unit.height && Object.keys(this.wntrProductProcessed.unit.height.options).length === 1 ) {
                this.chosen_height = Object.keys(this.wntrProductProcessed.unit.height.options)[0]
            }
            if ( this.wntrProductProcessed.unit && this.wntrProductProcessed.unit.depth && Object.keys(this.wntrProductProcessed.unit.depth.options).length === 1 ) {
                this.chosen_depth = Object.keys(this.wntrProductProcessed.unit.depth.options)[0]
            }

            // For hinge hold drilling of non-door products, get fixed dimensions and set those as final dimensions
            if (this.wntrProductProcessed.width) {
                this.final_width = this.wntrProductProcessed.width
            }
            if (this.wntrProductProcessed.height) {
                this.final_height = this.wntrProductProcessed.height
            }

            this.final_price = this.wntrProductProcessed.price
            this.final_sale_price = this.wntrProductProcessed.sale_price
        },
        processProductImages() {
            if ( this.wntrProductProcessed.main_image ) {
                this.product_images = []
                this.product_images.push(this.wntrProductProcessed.main_image)
            }
            if ( this.wntrProductProcessed.other_images ) {
                this.product_images = this.product_images.concat(this.wntrProductProcessed.other_images)
            }
            if ( this.product_images.length && this.wntrProductProcessed.finishes ) {
                for ( var i in this.wntrProductProcessed.finishes ) {
                    this.wntrProductProcessed.finishes[i].images = this.wntrProductProcessed.finishes[i].images.concat(this.product_images)
                }
            }
        },
        updateQuantity (quantity) {
            this.quantity = Number(quantity)
        },
        async retrieveDetailsExecute(id) {

            this.product_id = id

            const WntrProductDetails = ref<any | null>(null)

            var specifications = JSON.parse(localStorage.getItem('specifications'))
            var query_params = {
                product_id: id
            }
            var query = Object.assign({}, query_params, specifications);

            // console.log("*** getProductDetails({ product_id: '" + id + "'}) ***")
            WntrProductDetails.value = await this.getProductDetails(query)
            // console.log(WntrProductDetails.value)

            return WntrProductDetails.value
        },
        checkDisabled() {
            if ( this.wntrProductProcessed.true_handleless && this.wntrProductProcessed.true_handleless.options ) {
                if ( this.chosen_true_handleless === null ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.finishes && this.wntrProductProcessed.finishes.length ) {
                if ( this.chosen_finish === 0 && this.activeFinish === false ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.unit && this.wntrProductProcessed.unit.width ) {
                if ( this.wntrProductProcessed.unit.limits.double_width ) {
                    if ( this.chosen_width === null || ( this.chosen_width === "custom" && ( this.final_width_left === null || this.final_width_right === null ) ) ) {
                        return true
                    }
                } else {
                    if ( this.final_width === null ) {
                        return true
                    }
                }
            }
            if ( this.wntrProductProcessed.unit && this.wntrProductProcessed.unit.height ) {
                if ( this.final_height === null ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.unit && this.wntrProductProcessed.unit.depth ) {
                if ( this.final_depth === null ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.unit_hinge_drilling && this.wntrProductProcessed.unit_hinge_drilling.options ) {
                if ( this.chosen_unit_hinge_drilling === null ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.door_hinges && this.wntrProductProcessed.door_hinges.options ) {
                if ( this.chosen_door_hinges === null ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.dummy_drawer_clips && this.wntrProductProcessed.dummy_drawer_clips.options ) {
                if ( this.chosen_dummy_drawer_clips === null ) {
                    return true
                }
            }
            if ( this.wntrProductProcessed.dummy_drawer_plates && this.wntrProductProcessed.dummy_drawer_plates.options ) {
                if ( this.chosen_dummy_drawer_plates === null ) {
                    return true
                }
            }
            return false
        },
        proceedIfEnabled(to) {
            if ( this.checkDisabled() === false ) {
                this.$router.push(to)
            }
        },
        getApplicable(data, key, reset_key, value) {
            var options = []
            var that = this
            var reset_value = false
            data.options.forEach(function(element){
                if ( !element.hasOwnProperty("depends_on") ) {
                    options.push(element)
                } else {
                    var applicable = true
                    element.depends_on.forEach(function(condition){
                        switch (condition.name) {
                            case 'width':
                            case 'height':
                            case 'depth':
                                var field_name = 'final_' + condition.name
                            break
                            default:
                                var field_name = 'chosen_' + condition.name
                            break;
                        }
                        switch (condition.type) {
                            case 'not_equal':
                                if ( !( that[field_name] != condition.value ) ) {
                                    applicable = false
                                }
                            break
                            case 'equal':
                                if ( !( that[field_name] == condition.value ) ) {
                                    applicable = false
                                }
                            break
                            case 'min':
                                if ( !( that[field_name] >= condition.value ) ) {
                                    applicable = false
                                }
                            break
                            case 'max':
                                if ( !( that[field_name] <= condition.value ) ) {
                                    applicable = false
                                }
                            break
                        }
                    })
                    if ( applicable ) {
                        options.push(element)
                    }
                }
            })

            if (value) {
                var allowed_keys = options.map(function(element){ return element.key })
                var reset_required = !allowed_keys.includes(value)
                if (reset_required) {
                    this[key] = null
                }
            }

            return options
        },
        getValue(data, key, value, field) {
            for ( let i in data ) {
                if (data[i][key] == value) {
                    return data[i][field]
                }
            }
            return false
        },
        updateSelectedFinish(value) {
            this.activeFinish = true
            this.chosen_finish = value
            if (process.client) {
                this.$nextTick(() => {
                    // @ts-ignore
                    this.reInit();
                });
            }
            // this.setSpecification('default_door_product', this.wntrProductProcessed.finishes[value].door_product_id)
            // this.setSpecification('default_door_finish', this.wntrProductProcessed.finishes[value].id)
        },
        updateSelectedCabinetFinish(value) {
            this.chosen_cabinet_finish = value
            // this.setSpecification('default_carcass_finish', this.wntrProductProcessed.cabinet_finishes[value].id)
        },
        // updateSelectedGrainDirection(value) {
        //     this.chosen_grain_direction = value
        //     // this.setSpecification('default_door_grain', this.wntrProductProcessed.grain_directions.options[value].id)
        // },
        updateSelectedEdge(value) {
            this.chosen_edge = value
            // this.setSpecification('default_door_edge', this.wntrProductProcessed.edges[value].id)
        },
        updateSelectedStorageOption(index) {
            if (index === null) {
                this.chosen_storage_options_key = null
                this.chosen_storage_options = null
            } else {
                this.chosen_storage_options_key = index
                this.chosen_storage_options = this.storage_options.options[index].value
            }
        },
        updateSelectedGlazing(value) {
            this.chosen_glazing = value
            // this.setSpecification('default_door_glazing', this.wntrProductProcessed.glazing.size_options[value].id)
        },
        setSelectedProductType(index) {
            this.chosen_product_type_key = index
            this.chosen_product_type = this.productTypes[index].key
        },
        checkForMultipleProductTypes() {
            var productTypesWithProducts = 0
            var productTypeWithProducts = null
            if ( this.wntrProductProcessed.door_sizes ) {
                for ( let i in this.productTypes ) {
                    var chosen_product_type = this.productTypes[i].key
                    var matches = this.wntrProductProcessed.door_sizes.size_options.filter((item) => item[chosen_product_type] == true)
                    if ( matches.length ) {
                        productTypesWithProducts++
                        productTypeWithProducts = chosen_product_type
                    }
                }
            }
            if (productTypesWithProducts) {
                this.chosen_product_type = productTypeWithProducts
            }
            return (productTypesWithProducts > 1)
        },
        productTypesOccurrence(index) {
            if ( this.wntrProductProcessed.door_sizes ) {
                var chosen_product_type = this.productTypes[index].key
                var matches = this.wntrProductProcessed.door_sizes.size_options.filter((item) => item[chosen_product_type] == true)
                return matches.length
            }
        },
        getSelectedProductTypeSizes() {
            if ( this.wntrProductProcessed.door_sizes ) {
                var matches = this.wntrProductProcessed.door_sizes.size_options.filter((item) => item[this.chosen_product_type] == true)
                return matches
            }
        },
        isChosenSizeCustom() {
            if ( !this.chosen_size )
                return false
            try {
                var chosen_size = JSON.parse(this.chosen_size)
                return chosen_size.name === 'Custom'
            } catch (e) {
                return this.chosen_size === 'Custom'
            }
        },
        isChosenVariantCustom() {
            if ( !this.chosen_variant )
                return false
            try {
                chosen_variant = JSON.parse(this.chosen_variant)
                return chosen_variant.name === 'custom'
            } catch (e) {
                return this.chosen_variant === 'custom'
            }
        },
        checkCustomWidth() {
            var initial_custom_width = parseInt(this.custom_width)
            if ( this.wntrProductProcessed.unit.limits.limits && this.wntrProductProcessed.unit.limits.limits.width ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.width[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.width[1])

                if (this.wntrProductProcessed.unit.limits.tall_height_for_width_limit) {
                    if ( this.final_height >= parseInt(this.wntrProductProcessed.unit.limits.tall_height_for_width_limit) ) {
                        min = parseInt(this.wntrProductProcessed.unit.limits.limits.width_tall[0])
                        max = parseInt(this.wntrProductProcessed.unit.limits.limits.width_tall[1])
                    }
                }
                var custom_width = parseInt(this.custom_width)
                if ( custom_width < min ) {
                    this.custom_width = min
                }
                if ( custom_width > max ) {
                    this.custom_width = max
                }
            }
            this.show_custom_width_adjustment_message = ( initial_custom_width != this.custom_width ) ? true : false
            this.final_width = this.custom_width
        },
        checkCustomWidthLeft() {
            var initial_custom_width_left = parseInt(this.custom_width_left)
            if ( this.wntrProductProcessed.unit.limits.limits && this.wntrProductProcessed.unit.limits.limits.width ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.width[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.width[1])
                
                var custom_width_left = parseInt(this.custom_width_left || 0)
                var custom_width_right = parseInt(this.custom_width_right || 0)
                if ( custom_width_left < min ) {
                    this.custom_width_left = min
                }
                if ( custom_width_right < min ) {
                    custom_width_right = min
                }
                if ( custom_width_left + custom_width_right > max) {
                    this.custom_width_left = max - custom_width_right
                }
            }
            this.show_custom_width_left_adjustment_message = ( initial_custom_width_left != this.custom_width_left ) ? true : false
            this.final_width_left = this.custom_width_left
        },
        checkCustomWidthRight() {
            var initial_custom_width_right = parseInt(this.custom_width_right)
            if ( this.wntrProductProcessed.unit.limits.limits && this.wntrProductProcessed.unit.limits.limits.width ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.width[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.width[1])
                
                var custom_width_left = parseInt(this.custom_width_left || 0)
                var custom_width_right = parseInt(this.custom_width_right || 0)
                if ( custom_width_left < min ) {
                    custom_width_left = min
                }
                if ( custom_width_right < min ) {
                    this.custom_width_right = min
                }
                if ( custom_width_right + custom_width_left > max) {
                    this.custom_width_right = max - custom_width_left
                }
            }
            this.show_custom_width_right_adjustment_message = ( initial_custom_width_right != this.custom_width_right ) ? true : false
            this.final_width_right = this.custom_width_right
        },
        checkCustomHeight() {
            var initial_custom_height = parseInt(this.custom_height)
            if ( this.wntrProductProcessed.unit.limits.limits && this.wntrProductProcessed.unit.limits.limits.height ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.height[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.height[1])
                var custom_height = parseInt(this.custom_height)
                if ( custom_height < min ) {
                    this.custom_height = min
                }
                if ( custom_height > max ) {
                    this.custom_height = max
                }
            }
            this.show_custom_height_adjustment_message = ( initial_custom_height != this.custom_height ) ? true : false
            this.final_height = this.custom_height
        },
        checkCustomDepth() {
            var initial_custom_depth = parseInt(this.custom_depth)
            if ( this.wntrProductProcessed.unit.limits.limits && this.wntrProductProcessed.unit.limits.limits.depth ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.depth[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.depth[1])
                var custom_depth = parseInt(this.custom_depth)
                if ( custom_depth < min ) {
                    this.custom_depth = min
                }
                if ( custom_depth > max ) {
                    this.custom_depth = max
                }
            }
            this.show_custom_depth_adjustment_message = ( initial_custom_depth != this.custom_depth ) ? true : false
            this.final_depth = this.custom_depth
        },
        checkCustomVariantWidth() {
            if (
                this.wntrProductProcessed.unit &&
                this.wntrProductProcessed.unit.limits.limits &&
                this.wntrProductProcessed.unit.limits.limits.width
            ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.width[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.width[1])

                if (this.wntrProductProcessed.unit.limits.tall_height_for_width_limit) {
                    if ( this.final_height >= parseInt(this.wntrProductProcessed.unit.limits.tall_height_for_width_limit) ) {
                        min = parseInt(this.wntrProductProcessed.unit.limits.limits.width_tall[0])
                        max = parseInt(this.wntrProductProcessed.unit.limits.limits.width_tall[1])
                    }
                }
                var custom_variant_width = parseInt(this.custom_variant_width)
                if ( custom_variant_width < min ) {
                    this.custom_variant_width = min
                }
                if ( custom_variant_width > max ) {
                    this.custom_variant_width = max
                }
            }
            this.final_variant_width = this.custom_variant_width
        },
        checkCustomVariantHeight() {
            if (
                this.wntrProductProcessed.unit &&
                this.wntrProductProcessed.unit.limits.limits &&
                this.wntrProductProcessed.unit.limits.limits.height
            ) {
                var min = parseInt(this.wntrProductProcessed.unit.limits.limits.height[0])
                var max = parseInt(this.wntrProductProcessed.unit.limits.limits.height[1])
                var custom_variant_height = parseInt(this.custom_variant_height)
                if ( custom_variant_height < min ) {
                    this.custom_variant_height = min
                }
                if ( custom_variant_height > max ) {
                    this.custom_variant_height = max
                }
            }
            this.final_variant_height = this.custom_variant_height
        },
        checkCustomDimension(dimension) {
            var that = this
            if ( this.wntrProductProcessed.custom_dimensions ) {
                this.wntrProductProcessed.custom_dimensions.forEach(function(element) {
                    if ( element[dimension] ) {
                        var min = parseInt(element[dimension].min)
                        var max = parseInt(element[dimension].max)
                        that['custom_dimension_'+dimension] = parseInt(that['custom_dimension_'+dimension])
                        if ( that['custom_dimension_'+dimension] < min ) {
                            that['custom_dimension_'+dimension] = min
                        }
                        if ( that['custom_dimension_'+dimension] > max ) {
                            that['custom_dimension_'+dimension] = max
                        }
                    }
                })
            }
            this.getUpdatedPrice()
        },
        checkBlankHeight() {
            if ( this.wntrProductProcessed.blank_height.limits ) {
                var min = parseInt(this.wntrProductProcessed.blank_height.limits.min)
                var max = parseInt(this.wntrProductProcessed.blank_height.limits.max)
                var blank_height = parseInt(this.chosen_blank_height)
                if ( blank_height < min ) {
                    this.chosen_blank_height = min
                }
                if ( blank_height > max ) {
                    this.chosen_blank_height = max
                }
            }
            this.getUpdatedPrice()
        },
        // checkServiceVoid() {
        //     if ( this.wntrProductProcessed.service_void.limits ) {
        //         var min = parseInt(this.wntrProductProcessed.service_void.limits.min)
        //         var max = parseInt(this.wntrProductProcessed.service_void.limits.max)
        //         var service_void = parseInt(this.chosen_service_void)
        //         if ( service_void < min ) {
        //             this.chosen_service_void = min
        //         }
        //         if ( service_void > max ) {
        //             this.chosen_service_void = max
        //         }
        //     }
        //     this.getUpdatedPrice()
        // },
        getHingeHoles() {
            var holes = []
            if ( this.final_width || this.final_height ) {
                var size_option = this.wntrProductProcessed.hinge_drilling.size_options[this.chosen_hinge_type_key]
                var current_dimension_value = this['final_'+size_option.dimension]
                size_option.limits.forEach(item => {
                    if (
                        parseInt(item['min_length']) <= parseInt(current_dimension_value) &&
                        parseInt(item['max_length']) >= parseInt(current_dimension_value))
                    {
                        for ( var i = 0; i < item['number_of_holes']; i++ ) {
                            var direction = null
                            switch (size_option.dimension) {
                                case 'height':
                                    direction = (i < item['number_of_holes']/2) ? 'top' : 'bottom'
                                break;
                                case 'width':
                                    direction = (i < item['number_of_holes']/2) ? 'left' : 'right'
                                break;
                            }
                            holes.push({
                                label: 'mm from ' + direction,
                                min_distance: parseInt(item['min_distance']),
                                dimension: size_option.dimension
                            })
                        }
                    }
                })
            }
            this.holes = holes
            return holes
        },
        checkHole(index) {
            var min = this.holes[index]['min_distance']
            var max = this['final_'+this.holes[index]['dimension']] - min
            if ( this.hole_values[index] < min ) {
                this.hole_values[index] = min
            }
            if ( this.hole_values[index] > max ) {
                this.hole_values[index] = max
            }

            var store_chosen_hinge_type = this.chosen_hinge_type;
            this.chosen_hinge_type = 0
            this.$nextTick(() => {
                // @ts-ignore
                this.chosen_hinge_type = store_chosen_hinge_type
            });
        },
        isHandleOptionAvailableForSize(index) {
            var current_option = this.wntrProductProcessed.handle_position.size_options[index]
            if (
                this.final_width >= current_option.min_width && this.final_width <= current_option.max_width &&
                this.final_height >= current_option.min_height && this.final_height <= current_option.max_height
            ) {
                return true
            }
            return false
        },
        reInit() {
            this.$nextTick(() => {
                if ( Array.isArray(this.$refs.featureListMobile) && this.$refs.featureListMobile.length ) {
                    for ( var i in this.$refs.featureListMobile ) {
                        var featureListItem = this.$refs.featureListMobile[i];
                        if (process.client) {
                            // @ts-ignore
                            this.$refs.featureListMobile[i].reSlick();
                        }
                    }
                }
                if ( Array.isArray(this.$refs.featureListDesktop) && this.$refs.featureListDesktop.length ) {
                    for ( var i in this.$refs.featureListDesktop ) {
                        var featureListItem = this.$refs.featureListDesktop[i];
                        if (process.client) {
                            // @ts-ignore
                            this.$refs.featureListDesktop[i].reSlick();
                        }
                    }
                }
                if ( Array.isArray(this.$refs.gallery) && this.$refs.gallery.length ) {
                    for ( var i in this.$refs.gallery ) {
                        if (process.client) {
                            // @ts-ignore
                            this.$refs.gallery[i].reSlick();
                        }
                    }
                }
            });
        },
        async getUpdatedPrice() {

            this.disable_add_to_basket = true

            // console.log('----- getUpdatedPrice -----')
            const wntrProductPrices = ref<WntrProductPrice[] | null>(null);

            var query = {
                default_complete_on: true,
                
                // product_id: this.wntrProductProcessed.id,
                product_id: this.product_id,
                true_handleless: this.chosen_true_handleless,
                finish: this.chosen_finish,
                product_type: this.chosen_product_type,
                unit_handing: this.chosen_unit_handing,
                unit_hinge_drilling: this.chosen_unit_hinge_drilling,
                split_door: this.chosen_split_door,
                door_hinges: this.chosen_door_hinges,
                dummy_drawer_clips: this.chosen_dummy_drawer_clips,
                dummy_drawer_plates: this.chosen_dummy_drawer_plates,
                gas_lift_kit: this.chosen_gas_lift_kit,
                tambour_door_kit: this.chosen_tambour_door_kit,
                blank_height: this.chosen_blank_height,
                service_void: this.chosen_service_void,
                sides_and_plinths: this.chosen_sides_and_plinths,
                // drawer_box_option: this.chosen_drawer_box_option,
                
                chosen_height: this.chosen_height,
                chosen_width: this.chosen_width,
                // chosen_width_left: this.chosen_width_left,
                // chosen_width_right: this.chosen_width_right,
                chosen_depth: this.chosen_depth,

                custom_height: this.custom_height,
                custom_width: this.custom_width,
                custom_width_left: this.custom_width_left,
                custom_width_right: this.custom_width_right,
                custom_depth: this.custom_depth,

                height: this.final_height,
                width: this.final_width,
                width_left: this.final_width_left,
                width_right: this.final_width_right,
                depth: this.final_depth,
                
                // custom_dimension_height: this.custom_dimension_height,
                // custom_dimension_width: this.custom_dimension_width,
                // custom_dimension_length: this.custom_dimension_length,
                // custom_dimension_depth: this.custom_dimension_depth,
                size: this.chosen_size,
                hinge_type: this.chosen_hinge_type,
                cabinet_finish: this.chosen_cabinet_finish,
                grain_direction: this.chosen_grain_direction,
                edge: this.chosen_edge,
                glazing: this.chosen_glazing,
                handle_position: this.chosen_handle_position,

                door_type: this.chosen_door_type,
                chosen_hinge_type: this.chosen_hinge_type,

                holes: this.holes,
                hole_values: this.hole_values,
                variant: this.chosen_variant,
                variant_height: this.final_variant_height,
                variant_width: this.final_variant_width,
                // service_void: this.service_void,
                panel_style: this.chosen_panel_style,
                edge_profile: this.chosen_edge_profile,
                profiled_edges: this.chosen_profiled_edges,
                notes: this.notes,

                doors_available: this.chosen_doors_available,
                storage_options: this.chosen_storage_options
            }
            // console.log('----- QUERY -----')
            // console.log(query)

            // Merge custom dimensions
            if ( this.wntrProductProcessed.custom_dimensions ) {
                var custom_dimensions = {
                    height: this.custom_dimension_height,
                    width: this.custom_dimension_width,
                    length: this.custom_dimension_length,
                    depth: this.custom_dimension_depth
                }
                query = Object.assign({}, query, custom_dimensions)
            }

            // Merge hidden values
            if ( this.wntrProductProcessed.hidden_values && Object.keys(this.wntrProductProcessed.hidden_values).length ) {
                query = Object.assign({}, query, this.wntrProductProcessed.hidden_values)
            }

            var specifications = JSON.parse(localStorage.getItem('specifications'))
            query = Object.assign({}, query, specifications)
            
            if (this.$route.query) {
                query = Object.assign({}, query, this.$route.query)
            }

            wntrProductPrices.value = await this.getProductPrices(query)
            // console.log("*** getProductPrices({ " + query + " }) ***")
            // console.log(wntrProductPrices.value)

            if ( "price" in wntrProductPrices.value )
                this.final_price = wntrProductPrices.value.price
            
            if ( "sale_price" in wntrProductPrices.value )
                this.final_sale_price = wntrProductPrices.value.sale_price
            
            if ( "door_requirements" in wntrProductPrices.value )
                this.door_requirements = wntrProductPrices.value.door_requirements

            if ( "doors_available" in wntrProductPrices.value )
                this.doors_available = wntrProductPrices.value.doors_available
            
            if ( "storage_options" in wntrProductPrices.value ) {
                
                var previous_storage_options = this.chosen_storage_options
                this.storage_options = wntrProductPrices.value.storage_options

                if (previous_storage_options) {
                    var parsed_previous_storage_options = JSON.parse(previous_storage_options)
                    var previous_storage_options_sku = parsed_previous_storage_options.sku

                    var parsed_current_storage_options = JSON.parse(this.storage_options.options[this.chosen_storage_options_key].value)
                    var current_storage_options_sku = parsed_current_storage_options.sku
                    
                    if (previous_storage_options_sku != current_storage_options_sku) {
                        this.updateSelectedStorageOption(null)
                    }
                }
                
            }
            
            if ( "error" in wntrProductPrices.value )
                this.error = wntrProductPrices.value.error

            if ( this.final_price && !this.error )
                this.disable_add_to_basket = false

            if ( "allow_add_to_basket" in wntrProductPrices.value )
                this.allow_add_to_basket = wntrProductPrices.value.allow_add_to_basket

            if ( "doors_available" in wntrProductPrices.value ) {
                this.chosen_doors_available = wntrProductPrices.value.doors_available.value
            }
        },
        async addToCartAction() {

            if ( this.disable_add_to_basket || !this.allow_add_to_basket ) {
                return
            }

            this.disable_add_to_basket = true
            this.add_to_basket_label = this.add_to_basket_label_busy

            var query = {
                room: this.room,
                default_complete_on: true,

                // product_id: this.wntrProductProcessed.id,
                product_id: this.product_id,
                price: this.final_sale_price ? this.final_sale_price : this.final_price,
                title: this.wntrProductProcessed.title,
                true_handleless: this.chosen_true_handleless,
                finish: this.chosen_finish,
                product_type: this.chosen_product_type,
                unit_handing: this.chosen_unit_handing,
                unit_hinge_drilling: this.chosen_unit_hinge_drilling,
                split_door: this.chosen_split_door,
                door_hinges: this.chosen_door_hinges,
                dummy_drawer_clips: this.chosen_dummy_drawer_clips,
                dummy_drawer_plates: this.chosen_dummy_drawer_plates,
                gas_lift_kit: this.chosen_gas_lift_kit,
                tambour_door_kit: this.chosen_tambour_door_kit,
                blank_height: this.chosen_blank_height,
                service_void: this.chosen_service_void,
                sides_and_plinths: this.chosen_sides_and_plinths,
                // drawer_box_option: this.chosen_drawer_box_option,
                
                chosen_height: this.chosen_height,
                chosen_width: this.chosen_width,
                // chosen_width_left: this.chosen_width_left,
                // chosen_width_right: this.chosen_width_right,
                chosen_depth: this.chosen_depth,

                custom_height: this.custom_height,
                custom_width: this.custom_width,
                custom_width_left: this.custom_width_left,
                custom_width_right: this.custom_width_right,
                custom_depth: this.custom_depth,

                height: this.final_height,
                width: this.final_width,
                width_left: this.final_width_left,
                width_right: this.final_width_right,
                depth: this.final_depth,
                
                // custom_dimension_height: this.custom_dimension_height,
                // custom_dimension_width: this.custom_dimension_width,
                // custom_dimension_length: this.custom_dimension_length,
                // custom_dimension_depth: this.custom_dimension_depth,
                size: this.chosen_size,
                hinge_type: this.chosen_hinge_type,
                cabinet_finish: this.chosen_cabinet_finish,
                grain_direction: this.chosen_grain_direction,
                edges: this.chosen_edge,
                glazing: this.chosen_glazing,
                handle_position: this.chosen_handle_position,

                door_type: this.chosen_door_type,
                chosen_hinge_type: this.chosen_hinge_type,

                holes: this.holes,
                hole_values: this.hole_values,
                variant: this.chosen_variant,
                variant_height: this.final_variant_height,
                variant_width: this.final_variant_width,
                // service_void: this.service_void,
                panel_style: this.chosen_panel_style,
                edge_profile: this.chosen_edge_profile,
                profiled_edges: this.chosen_profiled_edges,
                notes: this.notes,

                doors_available: this.chosen_doors_available,
                storage_options: this.chosen_storage_options,

                qty: this.quantity
            };

            // Merge custom dimensions
            if ( this.wntrProductProcessed.custom_dimensions ) {
                var custom_dimensions = {
                    height: this.custom_dimension_height,
                    width: this.custom_dimension_width,
                    length: this.custom_dimension_length,
                    depth: this.custom_dimension_depth
                }
                query = Object.assign({}, query, custom_dimensions)
            }

            // Merge hidden values
            if ( this.wntrProductProcessed.hidden_values && Object.keys(this.wntrProductProcessed.hidden_values).length ) {
                query = Object.assign({}, query, this.wntrProductProcessed.hidden_values)
            }

            // var specifications = JSON.parse(localStorage.getItem('specifications'))
            var specifications = {}
            if (process.client) {
                if (window && window.localStorage) {
                    specifications = JSON.parse(window.localStorage.getItem('specifications'))
                    // console.log('----- specifications -----')
                    // console.log(specifications)
                }
            }
            // console.log('----- Specifications -----')
            // console.log(specifications)
            query = Object.assign({}, query, specifications)
            // console.log('----- MERGED QUERY -----')
            // console.log(query)

            var res = null
            const currentUser = localStorage.getItem('wntrUserSet');
            if (currentUser) {
                this.token = JSON.parse(currentUser).token;
            }
            var loggedIn = this.token !== '' ? true : false;
            
            // console.log(loggedIn)
            if (loggedIn === false) {
                query = Object.assign({}, query, {guest_session: localStorage.getItem('guest_session')})
                res = await this.addToCart({
                    query: query
                });
            } else {
                // console.log('logged in')
                // console.log(this.token);
                res = await this.addToCart({
                    token: this.token,
                    query: query
                });
            }

            if (res && res.error) {
                this.error = res.error.message
            } else {
                this.$bvModal.show('modal-added-to-basket')
            }

            this.disable_add_to_basket = false
            this.add_to_basket_label = this.add_to_basket_label_default

            // console.log(res);
            this.refreshCart(res);
        }
    },
    watch: {
        chosen_true_handleless (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_cabinet_finish (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_grain_direction_key (newValue, oldValue) {
            this.chosen_grain_direction = this.wntrProductProcessed.grain_directions.options[newValue].key
        },
        chosen_grain_direction (newValue, oldValue) {
            // this.getUpdatedPrice()
        },
        // chosen_drawer_box_option_key (newValue, oldValue) {
        //     this.chosen_drawer_box_option = this.wntrProductProcessed.drawer_box_option.options[newValue].id
        // },
        // chosen_drawer_box_option (newValue, oldValue) {
        //     this.getUpdatedPrice()
        // },
        chosen_size (newValue, oldValue) {
            var chosen_size = JSON.parse(newValue)
            if ( chosen_size.width ) {
                this.final_width = chosen_size.width
            }
            if ( chosen_size.height ) {
                this.final_height = chosen_size.height
            }
            this.getUpdatedPrice()
        },
        chosen_width (newValue, oldValue) {
            this.final_width = null
            this.final_width_left = null
            this.final_width_right = null
            if (newValue === 'custom') {
                if ( this.wntrProductProcessed.unit.limits.double_width ) {
                    this.final_width_left = this.custom_width_left
                    this.final_width_right = this.custom_width_right
                } else {
                    this.final_width = this.custom_width
                }
            } else if (newValue) {
                this.final_width = newValue
            }
            // this.getUpdatedPrice()

        },
        chosen_height (newValue, oldValue) {
            if (parseInt(newValue) !== NaN) {
                this.final_height = newValue
            } else if (this.custom_height) {
                this.final_height = this.chosen_height
            } else {
                this.final_height = null
            }
            // this.getUpdatedPrice()

        },
        chosen_depth (newValue, oldValue) {
            if (parseInt(newValue) !== NaN) {
                this.final_depth = newValue
            } else if (this.custom_depth) {
                this.final_depth = this.chosen_depth
            } else {
                this.final_depth = null
            }
            // this.getUpdatedPrice()

        },
        final_width (newValue, oldValue) {
            this.updateSelectedGlazing(false)
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        final_width_left (newValue, oldValue) {
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        final_width_right (newValue, oldValue) {
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        final_height (newValue, oldValue) {
            this.updateSelectedGlazing(false)
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        final_depth (newValue, oldValue) {
            this.updateSelectedGlazing(false)
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        final_variant_width (newValue, oldValue) {
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        final_variant_height (newValue, oldValue) {
            this.$nextTick(() => {
                this.getUpdatedPrice()
            })
        },
        chosen_unit_handing (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_hinge_type_key (newValue, oldValue) {
            this.chosen_hinge_type = this.wntrProductProcessed.hinge_drilling.size_options[newValue].key
        },
        chosen_hinge_type (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_door_hinges (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_dummy_drawer_clips (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_dummy_drawer_plates (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_unit_hinge_drilling (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_variant (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_split_door (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_profiled_edges (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_glazing (newValue, oldValue) {
            this.chosen_handle_position = false
        },
        chosen_edge_profile (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_service_void (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_handle_position (newValue, oldValue) {
            this.chosen_hinge_type = null
        },
        chosen_gas_lift_kit_key (newValue, oldValue) {
            if (newValue) {
                this.chosen_gas_lift_kit = this.wntrProductProcessed.gas_lift_kit.options[newValue].key
            } else {
                this.chosen_gas_lift_kit = null
            }
        },
        chosen_gas_lift_kit (newValue, oldValue) {
            var that = this
            setTimeout(function(){
                that.getUpdatedPrice()
            }, 1000)
        },
        chosen_tambour_door_kit_key (newValue, oldValue) {
            if (newValue) {
                this.chosen_tambour_door_kit = this.wntrProductProcessed.tambour_door_kit.options[newValue].key
            } else {
                this.chosen_tambour_door_kit = null
            }
        },
        chosen_tambour_door_kit (newValue, oldValue) {
            var that = this
            setTimeout(function(){
                that.getUpdatedPrice()
            }, 1000)
        },
        chosen_sides_and_plinths (newValue, oldValue) {
            this.getUpdatedPrice()
        },
        chosen_doors_available (newValue, oldValue) {
            // if(!(Array.isArray(newValue) && newValue.length == 0)) {
            //     this.getUpdatedPrice()
            // }
            // this.getUpdatedPrice() no longer required since only one set of doors available
        },
        chosen_storage_options (newValue, oldValue) {
            this.getUpdatedPrice()
        }
    }
})
