







  import {
      defineComponent,
      ref,
  } from '@nuxtjs/composition-api';

  import { 
      WntrWishlistItem
  } from '~/modules/GraphQL/types';

  import { wntrUseCart } from '~/composables';
  import { useWntrWishlistStore } from "~/composables/wntrUseCart/useWntrWishlistStore";

  export default defineComponent({
      name: 'Favourite',
      setup() {
          const {
              getWishlist,
              addToWishlist,
              deleteFromWishlist
          } = wntrUseCart();

          const wishlist = ref<WntrWishlistItem[] | null>(null);
          const wishlistStore = useWntrWishlistStore();

          return {
              wishlist,
              addToWishlist,
              deleteFromWishlist,
              getWishlist,
              wishlistStore
          }
      },
      props: {
          // th_view: {
          //     type: String,
          //     default: '0'
          // },
          plain: {
              type: Boolean,
              default: false
          },
          product_id: {
              type: String,
              default: null
          },
          image: {
              type: [ Object, String ],
              default() {
                  return {}
              }
          },
          product_name: {
              type: String,
              default: null
          },
          url: {
              type: [ Object, String ],
              default() {
                  return {}
              }
          }
      },
      data() {
          return {
              token: '',
              active: false,
              favourites: [],
              mode: ''
          }
      },
      mounted() {

          if (window.localStorage.wntrUserSet != undefined) {
              this.token = JSON.parse(window.localStorage.wntrUserSet).token;
          }

          const thBit = 
              this.$route.query?.th_view == '1' ||
              this.th_view == '1' ? 
              'th_' : '';
          
          // console.log(this.$route.query?.th_view == '1');
          // console.log(this.th_view == '1' );
          // console.log(thBit);

          const mainBit = this.$route.fullPath.includes('bedroom') ? 'bedroom' : 'kitchen';
          this.mode = thBit + mainBit;
          
          if (localStorage.getItem('wntrWishlist') !== null) {
              
              const lsItem = JSON.parse(localStorage.getItem('wntrWishlist'));

              if (lsItem.length) {

                  lsItem.forEach(i => {
  
                      // console.log(i.product_id == this.product_id && i.mode == this.mode);
                      if (i.product_id == this.product_id && i.mode == this.mode) this.active = true;
                  });
              }
          }



      },
      methods: {
          
          async addToWishlistAction() {
              // console.log('Add to wishlist');

              if (this.token !== '') {

                  const res = await this.addToWishlist({
                      token: this.token,
                      customQuery: {
                          product_id: this.product_id,
                          name: this.product_name,
                          mode: this.mode,
                          image: this.image,
                          url: this.url
                      }
                  });
  
                  if (res == '200') {
                      
                      const currentWishlist = await this.getWishlist({
                          token: this.token
                      });
                      this.wishlistStore.$patch((state) => {
                          state.wntrWishlist = currentWishlist;
                      });
                      localStorage.setItem('wntrWishlist', JSON.stringify(currentWishlist));
                  }
              } else {

                  const productToBeAdded = {
                      product_id: this.product_id,
                      name: this.product_name,
                      mode: this.mode,
                      image: this.image,
                      url: this.url
                  }
                  // console.log('productToBeAdded')
                  // console.log(productToBeAdded)

                  if (localStorage.getItem('wntrWishlist') !== null) {
                      const item = JSON.parse(localStorage.getItem('wntrWishlist'));
                      
                      // console.log('item')
                      // console.log(item)

                      let alreadyIn = false;
                      if (item && item.length) {

                          item.forEach(p => {
                              if (p.product_id == this.product_id && p.mode == this.mode && p.url == this.url) alreadyIn = true;
                          });
                      } 

                      if (!alreadyIn) {
                          item.push(productToBeAdded)
                      }
                      localStorage.setItem('wntrWishlist', JSON.stringify(item));
                  } else {
                      localStorage.setItem('wntrWishlist', JSON.stringify([productToBeAdded]));
                  }
                  
                  const newWishlist = ref<WntrWishlistItem[] | null>(null);
                  newWishlist.value = JSON.parse(localStorage.getItem('wntrWishlist'));

                  this.wishlistStore.$patch((state) => {
                      state.wntrWishlist = JSON.parse(localStorage.getItem('wntrWishlist'));
                  });
              }

              this.active = true;
          },
          async deleteFromWishlistAction() {

              // console.log('Delete wishlist');

              if (this.token != '') {
                  const res = await this.deleteFromWishlist({
                      token: this.token,
                      customQuery: {
                          product_id: this.product_id
                      }
                  });

                  if (res == '200') localStorage.removeItem('wntrWishlist')

                  const currentWishlist = await this.getWishlist({
                      token: this.token
                  });

                  this.wishlistStore.$patch((state) => {
                      state.wntrWishlist = currentWishlist;
                  });

                  localStorage.setItem('wntrWishlist', JSON.stringify(currentWishlist));
              } else {

                  if (localStorage.getItem('wntrWishlist') == null) return;        
      
                  const item = JSON.parse(localStorage.getItem('wntrWishlist'));

                  if (item.length == 1) {

                      localStorage.removeItem('wntrWishlist');
                      this.wishlistStore.$patch((state) => {
                          state.wntrWishlist = [];
                      });
                  } else {

                      const newItem = item.filter(p => {
                          return  p.product_id !== this.product_id ||
                              (p.product_id === this.product_id && (p.mode !== this.mode || p.url !== this.url) )
                      });

                      localStorage.setItem('wntrWishlist', JSON.stringify(newItem))
                      this.wishlistStore.$patch((state) => {
                          state.wntrWishlist = newItem;
                      });
                  }
              }

              this.active = false;
          }
      }
});
