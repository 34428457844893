
















import {
    defineComponent,
    ref,
    useContext,
    onMounted,
    useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'FormInputNumber',
    components: {
        
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            quantity: this.value
        }
    },
    computed: {
    },
    methods: {
        decreaseQuantity() {
            if (this.disabled) return

            this.quantity = Number(this.quantity) - 1;
            if ( this.quantity < 1 ) {
                this.quantity = 1;
            }
        },
        increaseQuantity() {
            if (this.disabled) return
            
            this.quantity = Number(this.quantity) + 1;
        }, 
        setQty(qty) {
            if (this.disabled) return
            
            this.quantity = Number(qty);
        },
        validateQty() {
            if ( Number(this.quantity) < 1 ) {
                this.quantity = 1;
            }
        }
    },
    watch: {
        value(newValue, oldValue) {
            this.quantity = newValue
        },
        quantity(newValue, oldValue) {
            this.$emit('updateQuantityParent', newValue);
        }
    }
});
