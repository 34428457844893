import { render, staticRenderFns } from "./ProductRow.vue?vue&type=template&id=d9081e54&scoped=true&"
import script from "./ProductRow.vue?vue&type=script&lang=ts&"
export * from "./ProductRow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9081e54",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductRowDetails: require('/var/www/components/ProductRowDetails.vue').default})
